import { es_co_nationalities } from '../../../../../intl/es_co_nationalities';
import { es_mx_nationalities } from '../../../../../intl/es_mx_nationalities';

export const LABEL_SELECT = {
  es_kc: 'Elija Opción',
  es_mx: 'Elija Opción',
  es_km: 'Elija Opción *',
};

export const NATIONALITY_OPTIONS_MULTI = {
  es_kc: es_co_nationalities,
  es_mx: es_mx_nationalities,
  es_km: es_mx_nationalities,
};

export const NATIONALITY_OPTIONS_SINGLE = {
  es_kc: es_co_nationalities,
  es_mx: es_co_nationalities,
  es_km: es_co_nationalities,
};

export const COUNTRY_ORIGIN = {
  es_kc: 'Origen',
  es_mx: 'Origen',
  es_km: 'Origen',
};

export const COUNTRY_DESTINATION = {
  es_kc: 'Destino',
  es_mx: 'Destino',
  es_km: 'Destino *',
};
